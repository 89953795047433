import styled from 'styled-components';

export const Ul = styled.ul.attrs(props => ({
    
}))`
    display: flex;
    list-style: none;

    li {
        margin: 0 20px;
        color: #fff;
    }

    @media (max-width: 1025px) {
        display: block;
        background-color: #000;
        position: absolute;
        border-radius: 5px;
        z-index: 2;
        min-width: 150px;
        top: -20px;
        left: -20px;
        
        display: ${props => (props.mobile ? 'block' : 'none')};

        li {
            margin: 0;
            color: #fff;
            display: flex;
        }

        li:last-of-type {
            padding-bottom: 10px;
        }
    }
    
`;
import React from 'react';

import './styles.css';
import imgFeatured from '../../assets/img_destaque.svg';

import goTo from '../../services/goto';

export default function Featured() {
    return (
        <article className="container destaque_main">
            <div className="content">
                <div className="box_50">
                    <div>
                        <h1 className="big_title">DESCUBRA TODO O POTENCIAL DO PRANOTAS</h1>
                        <h2 className="sub_title">Nao se preocupe com a emissao da nota fiscal, deixe isso com a gente</h2>
                        <button onClick={() => goTo("#planos")} className="btn btn_main" type="button" name="button">COMECAR AGORA</button>
                    </div>
                </div>
                <div className="box_50" style={{ justifyContent: 'flex-end' }}>
                    <img className="img_destaque" src={imgFeatured} alt="Imagem Ilustrativa" />
                </div>
            </div>
        </article>
    );
}

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './styles.css';
import api from '../../services/api';

import load from '../../assets/load_w.gif';

export default function Contact() {
    const [sending, setSending] = useState(false);

    async function handleSubmit(e) {
        e.preventDefault();

        setSending(true);

        const form = e.target;

        const data = {
            fullName: e.target.fullName.value,
            businessName: e.target.businessName.value,
            email: e.target.email.value,
            phone: e.target.phone.value,
            message: e.target.message.value,
        }

        await api.post("/contact", data)
        .then((res) => {
            toast.success(res.data.message);

            form.reset();
        })
        .catch((er) => {
            console.log(er);
            
            toast.error("Ocorreu algum erro");
        });

        setSending(false);
    }
    
    return (
        <>
            <article id="contato" className="container contato">
                <div className="content">
                    <div className="box_50">
                        <h1 className="small_title">contate-nos</h1>
                        <h2 className="big_title">FICOU COM DUVIDAS? FIQUE A VONTADE PARA NOS MANDAR UM EMAIL</h2>
                    </div>
                    <div className="box_50 ds-flex" style={{ justifyContent: 'flex-end' }}>
                        <form onSubmit={handleSubmit} className="form" action="" method="post">
                            <label className="label_100">
                                <span className="legend">Nome Completo</span>
                                <input defaultValue="" type="text" name="fullName" required />
                            </label>
                            <label className="label_100">
                                <span className="legend">Nome da Empresa</span>
                                <input defaultValue="" type="text" name="businessName" required />
                            </label>
                            <label className="label_100">
                                <span className="legend">Email</span>
                                <input defaultValue="" type="email" name="email" required />
                            </label>
                            <label className="label_100">
                                <span className="legend">Telefone</span>
                                <input defaultValue="" type="text" name="phone" required />
                            </label>
                            <label className="label_100">
                                <span className="legend">Mensagem</span>
                                <textarea defaultValue="" name="message" rows="2" cols="30" required></textarea>
                            </label>

                            <button
                                className="btn btn_main"
                                type="submit"
                                name="button"
                                disabled={sending}
                            >
                                {sending ? (<img alt="Carregando" src={load} style={{ width: "30px" }} />) : "ENVIAR"}
                            </button>
                        </form>
                    </div>
                </div>
            </article >
        </>
    );
}

import React from 'react';

import './styles.css';
import logoBlack from '../../assets/logo_black.svg';

import goTo from '../../services/goto';

export default function Footer() {    
    return (
        <footer className="container footer">
            <div className="content">
                <div className="fd-row ds-flex ai-center footer_menu" style={{ flex: 1 }}>
                    <img className="footer_logo" src={logoBlack} alt="PraNotas" />
                    <ul>
                        <li><button onClick={() => goTo("#emissorOnline")}>emissor online</button></li>
                        <li><button onClick={() => goTo("#gateway")}>gateway</button></li>
                        <li><button onClick={() => goTo("#planos")}>planos</button></li>
                        <li><button onClick={() => goTo("#contato")}>contato</button></li>
                    </ul>
                </div>
                <div className="ds-flex jc-end ai-center">
                    <p>© 2020 pranotas. Todos os direitos reservados.</p>
                </div>
            </div>
        </footer>
    );
}

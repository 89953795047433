import React, { useState } from 'react';

import './styles.css';
import { Ul } from './styles';
import logo from '../../assets/logo.svg';
import mobile from '../../assets/mobile.svg';
import closeMobile from '../../assets/close_mobile.svg';

import goTo from '../../services/goto';

export default function Header() {
    const [mobileMenu, setMobileMenu] = useState(false);

    return (
        <header className="container header_main">
            <div className="content">
                <div className="ds-flex">
                    <h1 className="fontzero">PraNotas</h1>
                    <img className="logo" src={logo} alt="PraNotas" />

                    <div className="menu_main">
                        <div className="menu_mobile">
                            <img src={mobile} onClick={() => setMobileMenu(true)} alt="" />
                        </div>

                        <Ul mobile={mobileMenu}>
                            <li className="menu_mobile_close"><img src={closeMobile} onClick={() => setMobileMenu(false)} alt="" /></li>
                            <li><button onClick={() => goTo("#emissorOnline")}>emissor online</button></li>
                            <li><button onClick={() => goTo("#gateway")}>gateway</button></li>
                            <li><button onClick={() => goTo("#planos")}>planos</button></li>
                            <li><button onClick={() => goTo("#contato")}>contato</button></li>
                        </Ul>
                    </div>

                </div>

                <div className="ds-flex">
                    <a className="btn btn_entrar" href="//admin.pranotas.com.br">ENTRAR</a>

                    <select className="language" name="">
                        <option value="">portugues</option>
                    </select>
                </div>
            </div>
        </header>
    );
}

import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import api from '../../services/api';
import './styles.css';
import { getFlagPerCardNumber } from '../../services/flag';
import replaceAll from '../../services/replaceAll';

import closeModal from '../../assets/close_mobile.svg';

import visa from '../../assets/visa.png';
import master from '../../assets/mastercard.png';
import hiper from '../../assets/hiper.png';
import amex from '../../assets/amex.png';
import elo from '../../assets/elo.png';
import flagSvg from '../../assets/flag.svg';

import load from '../../assets/load_w.gif';

export default function Plans() {
    const [modalStyle, setModalStyle] = useState({ zIndex: '-1' });
    const [modalBoxStyle, setModalBoxStyle] = useState({ right: '-100%' });
    const [chosePlan, setChosePlan] = useState(0);
    const [step, setStep] = useState(0);
    const [cardNumber, setCardNumber] = useState("");
    const [expiration, setExpiration] = useState("");
    const [cardHolder, setCardHolder] = useState("");
    const [cardCvv, setCardCvv] = useState("");
    const [cardCvvFocus, setCardCvvFocus] = useState(false);
    const [sending, setSending] = useState(false);
    const [flag, setFlag] = useState(flagSvg);

    async function handleSubmit(e) {
        e.preventDefault();

        const form = e.target;

        const data = {
            plan: e.target.plan.value,
            name: e.target.name.value,
            email: e.target.email.value,
            phone: e.target.phone.value,
            cnpj: e.target.cnpj.value,
            cardHolderName: e.target.cardHolderName.value,
            cardNumber: e.target.cardNumber.value,
            cardCVV: e.target.cardCVV.value,
            expirationDate: e.target.expirationDate.value,
            status: "active"
        }

        setSending(true);

        await api.post("/subscription", data)
            .then((res) => {
                toast.success("Tudo certo logo entraremos em contato");

                form.reset();
                setCardNumber("");
                setExpiration("");
                setCardHolder("");
                setCardCvv("");

                handleModal(false);
            })
            .catch((er) => {
                console.log(er);

                toast.error("Ocorreu algum erro");
            });

        setSending(false);
    }

    function nextStep() {
        const name = document.querySelector('input[name="name"]').value;
        const email = document.querySelector('input[name="email"]').value;
        const phone = document.querySelector('input[name="phone"]').value;
        const cnpj = document.querySelector('input[name="cnpj"]').value;

        if (!name || !email || !phone || !cnpj) {
            toast.error("Preencha todos os campos");
            return;
        }

        setStep(1);
    }

    function handleModal(bool, plan) {
        if (bool) {
            setModalStyle({ zIndex: '1' });
            setModalBoxStyle({ right: '0' });
            setChosePlan(plan)
        } else {
            setModalStyle({ zIndex: '-1' });
            setModalBoxStyle({ right: '-100%' });
            setStep(0);
        }
    }

    useEffect(() => {
        const cn = replaceAll(cardNumber, " ", "");
        const flag = getFlagPerCardNumber(cn);

        if (flag === "visa") {
            setFlag(visa);
        } else if (flag === "master") {
            setFlag(master);
        } else if (flag === "hiper") {
            setFlag(hiper);
        } else if (flag === "amex") {
            setFlag(amex);
        } else if (flag === "elo") {
            setFlag(elo);
        } else {
            setFlag(flagSvg);
        }
    }, [cardNumber])
    return (
        <>
            <section id="planos" className="container planos">
                <div className="content">
                    <h1 className="small_title">planos</h1>
                    <h2 className="big_title" style={{ marginBottom: '35px' }}>O QUE VOCE PRECISA ?</h2>

                    <div className="container">
                        <article className="plan_box">
                            <header style={{ backgroundColor: '#63B5DA' }}>
                                <h1>BRONZE</h1>
                                <p>TUDO QUE VOCE PRECISA PARA COMECAR</p>
                            </header>

                            <div className="plan_box_body">
                                <p className="price">R$ 49,90</p>
                                <p className="mensal">por mes</p>

                                <div className="box_btn_contratar">
                                    <button
                                        onClick={() => handleModal(true, 0)}
                                        className="plan_box_btn"
                                        style={{ backgroundColor: '#63B5DA' }}
                                    >CONTRATAR</button>
                                </div>

                                <ul className="resources">
                                    <li>100 notas/mês</li>
                                    <li>+ R$0,69 por nota adicional</li>
                                    <li>Suporte especialista</li>
                                    <li>Atendimento via ticket e email</li>
                                </ul>
                            </div>
                        </article>

                        <article className="plan_box">
                            <header style={{ backgroundColor: '#0861A1' }}>
                                <h1>PRATA</h1>
                                <p>PLANO IDEAL PARA TODAS AS EMPRESAS</p>
                            </header>

                            <div className="plan_box_body">
                                <p className="price">R$ 99,90</p>
                                <p className="mensal">por mes</p>

                                <div className="box_btn_contratar">
                                    <button
                                        onClick={() => handleModal(true, 1)}
                                        className="plan_box_btn"
                                        style={{ backgroundColor: '#0861A1' }}
                                    >CONTRATAR</button>
                                </div>

                                <ul className="resources">
                                    <li>250 notas/mês</li>
                                    <li>+ R$0,49 por nota adicional</li>
                                    <li>Suporte especialista</li>
                                    <li>Atendimento via ticket e email</li>
                                    <li>Atendimento via Whatapp</li>
                                </ul>
                            </div>
                        </article>

                        <article className="plan_box">
                            <header style={{ backgroundColor: '#121E3D' }}>
                                <h1>OURO</h1>
                                <p>PLANO PARA GRANDES EMPRESAS</p>
                            </header>

                            <div className="plan_box_body">
                                <p className="price">R$ 159,90</p>
                                <p className="mensal">por mes</p>

                                <div className="box_btn_contratar">
                                    <button
                                        onClick={() => handleModal(true, 2)}
                                        className="plan_box_btn"
                                        style={{ backgroundColor: '#121E3D' }}
                                    >CONTRATAR</button>
                                </div>

                                <ul className="resources">
                                    <li>500 notas/mês</li>
                                    <li>+ R$0,29 por nota adicional</li>
                                    <li>Suporte especialista</li>
                                    <li>Atendimento via ticket e email</li>
                                    <li>Atendimento via Whatapp</li>
                                    <li>Atendimento via Telefone</li>
                                </ul>
                            </div>
                        </article>
                    </div>
                </div>
            </section>

            <div style={modalStyle} className="container-modal">
                <div className="content-modal">
                    <div style={modalBoxStyle} className="box-modal">
                        <div className="btn-close-modal">
                            <img onClick={() => handleModal(false)} src={closeModal} alt="" />
                        </div>
                        <div className="box-modal-content">
                            <form onSubmit={handleSubmit} className="form" action="" method="post">
                                <div className="chosePlan">
                                    <label className={chosePlan === 0 ? "plan active" : "plan"}>
                                        <p className="titlePlan">BRONZE</p>
                                        <p className="pricePlan">R$ 49,90</p>
                                        <input onChange={() => setChosePlan(0)} type="radio" name="plan" value="0" checked={chosePlan === 0} />
                                    </label>
                                    <label className={chosePlan === 1 ? "plan active" : "plan"}>
                                        <p className="titlePlan">PRATA</p>
                                        <p className="pricePlan">R$ 99,90</p>
                                        <input onChange={() => setChosePlan(1)} type="radio" name="plan" value="1" checked={chosePlan === 1} />
                                    </label>
                                    <label className={chosePlan === 2 ? "plan active" : "plan"}>
                                        <p className="titlePlan">OURO</p>
                                        <p className="pricePlan">R$ 149,90</p>
                                        <input onChange={() => setChosePlan(2)} type="radio" name="plan" value="2" checked={chosePlan === 2} />
                                    </label>
                                </div>

                                {/* DADOS BASICOS */}
                                <div className="steps" style={step === 0 ? { display: 'block' } : { display: 'none' }}>
                                    <label className="label_100">
                                        <span className="legend">Nome Completo</span>
                                        <input type="text" name="name" required />
                                    </label>
                                    <label className="label_100">
                                        <span className="legend">Email</span>
                                        <input type="email" name="email" required />
                                    </label>
                                    <label className="label_100">
                                        <span className="legend">Telefone</span>
                                        <input type="text" name="phone" required />
                                    </label>
                                    <label className="label_100">
                                        <span className="legend">CNPJ</span>
                                        <input type="text" name="cnpj" required />
                                    </label>

                                    <button
                                        onClick={nextStep}
                                        style={{ marginTop: '30px' }}
                                        className="btn btn_main"
                                        type="button"
                                        name="button"
                                    >PROXIMO</button>
                                </div>

                                {/* DADOS DO CARTAO */}
                                <div className="steps" style={step === 1 ? { display: 'block' } : { display: 'none' }}>
                                    <label className="label_100">
                                        <span className="legend">Nome no Cartão</span>
                                        <input onChange={(e) => setCardHolder(e.target.value)} type="text" name="cardHolderName" required />
                                    </label>
                                    <label className="label_100">
                                        <span className="legend">Numero do Cartão</span>
                                        <InputMask mask="9999 9999 9999 9999" onChange={(e) => setCardNumber(e.target.value)} type="text" name="cardNumber" required />
                                    </label>
                                    <label className="label_100">
                                        <span className="legend">CVV</span>
                                        <input
                                            onChange={(e) => setCardCvv(e.target.value)}
                                            type="text"
                                            name="cardCVV"
                                            required
                                            onFocus={() => setCardCvvFocus(true)}
                                            onBlur={() => setCardCvvFocus(false)}
                                        />
                                    </label>
                                    <label className="label_100">
                                        <span className="legend">Data de Expiração</span>
                                        <InputMask mask="99/99" onChange={(e) => setExpiration(e.target.value)} type="text" name="expirationDate" required />
                                    </label>

                                    {/* CARD SIMULATOR */}
                                    <div className="card_box">
                                        <div className="flip-card">
                                            <div className="flip-card-inner" style={cardCvvFocus ? { transform: "rotateY(180deg)" } : {}}>
                                                <div className="flip-card-front">
                                                    <div className="box_flag">
                                                        <img className="cardFlag" src={flag} alt="Bandeira" />
                                                    </div>

                                                    <span className="cardNumber">{cardNumber ? cardNumber : "xxxx xxxx xxxx xxxx"}</span>
                                                    <span className="cardExpiration">{expiration ? expiration : "xx/xx"}</span>
                                                    <span className="cardExpirationLabel">VALID THRU</span>
                                                    <span className="cardHolder">{cardHolder ? cardHolder : "xxxxxxxxxxxxxxxxxx"}</span>
                                                </div>
                                                <div className="flip-card-back">
                                                    <span className="cardCvv">{cardCvv ? cardCvv : "xxx"}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <button
                                        disabled={sending}
                                        style={{ marginTop: '30px' }}
                                        className="btn btn_main"
                                        type="submit"
                                        name="button"
                                    >
                                        {sending ? (<img alt="Carregando" src={load} style={{ width: "30px" }} />) : "ENVIAR"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

import React from 'react';

import './styles.css';
import mac from '../../assets/mac.png';
import codeBox from '../../assets/code_box.png';

import goTo from '../../services/goto';

export default function Products() {
    return (
        <>
            <article id="emissorOnline" className="container product_emissor_online">
                <div className="content">
                    <div className="box_divide" style={{ justifyContent: 'flex-end' }}>
                        <img src={mac} alt="Emissor Online PraNotas" />
                    </div>
                    <div className="box_divide">
                        <div style={{ paddingLeft: '40px' }}>
                            <h1 className="small_title">emissor online pranotas</h1>
                            <h2 className="big_title">MELHOR PLATAFORMA DE EMISSAO DE NOTAS ONLINE</h2>
                            <button className="btn btn_black fl-left" onClick={() => goTo("#planos")}>COMECAR</button>
                        </div>
                    </div>
                </div>
            </article>

            <article id="gateway" className="container product_gateway">
                <div className="content">
                    <div className="box_divide" style={{ justifyContent: 'flex-start' }}>
                        <img src={codeBox} alt="Emissor Online PraNotas" />
                    </div>
                    <div className="box_divide">
                        <div style={{ paddingLeft: '40px' }}>
                            <h1 className="small_title">gateway pranotas</h1>
                            <h2 className="big_title">API PARA INTEGRACAO COM SEU SISTEMA, SIMPLESMENTE SENSACIONAL</h2>
                            <button className="btn btn_black fl-left" onClick={() => goTo("#planos")}>COMECAR</button>
                        </div>
                    </div>
                </div>
            </article>
        </>
    );
}

import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './styles.css';
import Header from '../../components/Header';
import Featured from '../../components/Featured';
import Products from '../../components/Products';
import Plans from '../../components/Plans';
import Contact from '../../components/Contact';
import Footer from '../../components/Footer';

export default function LandingPage() {
    return (
        <>
            <Header />
            <main>
                <Featured />
                <Products />
                <Plans />
                <Contact />
            </main>
            <Footer />
            <ToastContainer autoClose={false} />
        </>
    );
}
